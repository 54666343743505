import { apiQueryRate_USD_IDR } from '@/resource'
import zotapayMixin from '@/mixins/page/deposit/zotapay'

export default {
  mixins: [zotapayMixin],
  data() {
    return {
      validCurrencies: ['USD', 'USC'],
    }
  },
  mounted() {
    this.queryRate()
  },
  methods: {
    queryRate() {
      apiQueryRate_USD_IDR()
        .then(resp => {
          if (resp.data.code == 0) this.rate = resp.data.data
        })
        .catch(err => {
          this.errorMessage(
            this.$t('deposit.default.rate.result.rateError', { oldCurrency: 'USD', newCurrency: 'IDR' })
          )
        })
    },
  },
}
